/* eslint-disable camelcase */
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import type { User as Auth0User } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { CPOHUB } from 'constants/companies';
import { RECRUITING_SCHEMAS } from 'constants/cubeNames';
import { COMPANY_KEY, FISCAL_YEAR_START_MONTH_KEY, ORG_ID_KEY } from 'constants/storageKeyVal';

import registerChartTheme from 'utils/registerChartTheme';
import {
  isCpoHqOrganization,
  userHasFieldVisibilityRestrictions,
  userHasPopulationVisibilityRestrictions,
  userIsGuest,
} from 'utils/userUtils';

import companyApi, { CompanyConfigurations, Vendors } from 'api/companyApi';
import permissionGroupsApi from 'api/permissionGroupsApi';
import userApi, { UserAnalyticsProfile } from 'api/userApi';
import { AUTH0_AUDIENCE } from 'config';

const IS_FIRST_LOGIN_KEY = 'https://knoetic.com/is_first_login';

export interface UserInfo extends Auth0User {
  name: string;
  email: string;
  email_verified?: boolean;
  family_name: string;
  given_name: string;
  permissions: string[];
  picture?: string;
  sub: string;
}

export interface UserContextInterface {
  user: UserInfo;
  setUser: Dispatch<SetStateAction<UserInfo | Auth0User | undefined>>;
  companyVendors: Vendors;
  companyConfigurations: CompanyConfigurations;
  setCompanyConfigurations: Dispatch<SetStateAction<CompanyConfigurations>>;
  setCompanyVendors: Dispatch<SetStateAction<Vendors>>;
  analyticsProfile: UserAnalyticsProfile;
  setAnalyticsProfile: Dispatch<SetStateAction<UserAnalyticsProfile>>;
  fiscalYearStartMonth: number;
  initialized: boolean;
  loading: boolean;
  error?: Error | unknown | null;
  hasFieldVisibilityRestrictions: boolean;
  hasPopulationVisibilityRestrictions: boolean;
  hasAtsRestrictions: boolean;
  isFirstLogin: boolean;
}

const defaultAnalyticsProfile: UserAnalyticsProfile = {
  accountType: 'normal',
  auth0_user_id: '',
  optica_id: null,
  org: {
    id: '',
    name: '',
    display_name: '',
  },
  email: '',
  family_name: '',
  given_name: '',
  hasCompletedProfile: false,
  picture: '',
  app_metadata: {
    admin: {
      staff: true,
      superadmin: true,
    },
    omed: {
      nodes_visible: [],
      population_visibility: 'full',
      field_visibility: 'full',
      hris_employee_id: null,
    },
  },
  is_first_login: false,
};

export const UserContext = createContext<UserContextInterface>({
  user: {
    name: '',
    email: '',
    email_verified: false,
    family_name: '',
    given_name: '',
    permissions: [],
    picture: '',
    sub: '',
    currentAts: '',
  },
  setUser: () => null,
  companyVendors: {},
  companyConfigurations: {
    fiscalYearStartMonth: 1,
    colorScheme: [],
    repeatColorScheme: false,
    organizationLeadershipTitles: [],
    organizationLeadershipReports: [],
    hasEngagementData: false,
  },
  setCompanyConfigurations: () => null,
  setCompanyVendors: () => null,
  analyticsProfile: defaultAnalyticsProfile,
  setAnalyticsProfile: () => undefined,
  initialized: false,
  loading: false,
  error: undefined,
  fiscalYearStartMonth: 1,
  hasFieldVisibilityRestrictions: false,
  hasPopulationVisibilityRestrictions: false,
  hasAtsRestrictions: false,
  isFirstLogin: false,
});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { getIdTokenClaims, getAccessTokenSilently, user: auth0User } = useAuth0();

  const [user, setUser] = useState<UserInfo | Auth0User | undefined>(auth0User);
  const [companyVendors, setCompanyVendors] = useState<Vendors>({});
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<Error | unknown | null>(null);
  const [analyticsProfile, setAnalyticsProfile] = useState<UserAnalyticsProfile>(defaultAnalyticsProfile);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [hasFieldVisibilityRestrictions, setHasFieldVisibilityRestrictions] = useState(true);
  const [hasPopulationVisibilityRestrictions, setHasPopulationVisibilityRestrictions] = useState(true);
  const [hasAtsRestrictions, setHasAtsRestrictions] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [companyConfigurations, setCompanyConfigurations] = useState<CompanyConfigurations>({
    fiscalYearStartMonth: 1,
    colorScheme: [],
    repeatColorScheme: false,
    organizationLeadershipTitles: [],
    organizationLeadershipReports: [],
    hasEngagementData: false,
  });

  const ldClient = useLDClient();

  useEffect(() => {
    if (initialized || loading) return;

    const fetchAnalyticsProfile = async () => {
      setLoading(true);

      const claims = await getIdTokenClaims();
      localStorage.setItem(ORG_ID_KEY, claims?.org_id as string);

      const accessTokenVal = await getAccessTokenSilently({
        authorizationParams: {
          redirect_uri: window.location.origin,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          audience: AUTH0_AUDIENCE,
          organization: localStorage.getItem(ORG_ID_KEY),
        },
      });
      const decodedAccessToken: UserInfo = jwtDecode(accessTokenVal);
      setPermissions(decodedAccessToken.permissions);

      // TODO: To optimise endpoint, because this endpoint slows the initial load time
      const analyticsProfileData = await userApi.getUser();
      setAnalyticsProfile(analyticsProfileData);

      const company = analyticsProfileData.org.name;
      sessionStorage.setItem(COMPANY_KEY, company);

      if (company !== CPOHUB) {
        if (!userIsGuest(auth0User as UserInfo)) {
          const companyVendorsData = await companyApi.getCompanyVendors();
          setCompanyVendors(companyVendorsData);
        }

        const companyConfigurationsData = await companyApi.getCompanyConfigurations();
        setCompanyConfigurations(companyConfigurationsData);
        registerChartTheme(companyConfigurationsData.colorScheme, companyConfigurationsData.repeatColorScheme);
      }

      sessionStorage.setItem(FISCAL_YEAR_START_MONTH_KEY, companyConfigurations.fiscalYearStartMonth.toString());
      const _userHasFieldVisibilityRestrictions = userHasFieldVisibilityRestrictions(analyticsProfileData);
      const _userHasPopulationVisibilityrestrictions = userHasPopulationVisibilityRestrictions(analyticsProfileData);

      if (_userHasFieldVisibilityRestrictions) {
        const groups = await permissionGroupsApi.getActivePermissionGroups();
        const userEmail = analyticsProfileData?.email;
        const userPermissionGroup = groups?.find((group) =>
          group.users?.find(({ userId }) => userId === userEmail || userId === auth0User?.sub),
        );

        if (userPermissionGroup) {
          if (
            userPermissionGroup?.missingFields?.some(({ field }) => field && RECRUITING_SCHEMAS.includes(field.schema))
          ) {
            setHasAtsRestrictions(true);
          }
        }

        setIsFirstLogin(!!claims?.[IS_FIRST_LOGIN_KEY]);
      }

      setHasPopulationVisibilityRestrictions(_userHasPopulationVisibilityrestrictions);
      setHasFieldVisibilityRestrictions(_userHasFieldVisibilityRestrictions);

      // Initialize feature flag with LaunchDarkly
      if (ldClient) {
        await ldClient.identify({
          key: user?.sub,
          name: user?.name,
          email: user?.email,
          custom: { company: analyticsProfileData.org.name, isCpohqOrg: isCpoHqOrganization(user as UserInfo) },
        });
      } else {
        throw new Error('Unable to initialize LaunchDarkly client in UserProvider. ldClient is undefined.');
      }
    };

    fetchAnalyticsProfile()
      .catch((nextError: Error | unknown) => {
        Sentry.captureException(nextError);
        setError(nextError);
      })
      .finally(() => {
        setInitialized(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0User]);

  // avoid generating duplication
  const userWithAuth = useMemo(() => {
    return {
      ...user,
      permissions,
      // given/family name may not always be there. Evaluate them from `.name` instead
      ...(auth0User && {
        given_name: auth0User.given_name || auth0User.name?.split(' ')[0],
        family_name: auth0User.family_name || auth0User.name?.split(' ').slice(1).join(' '),
      }),
    };
  }, [user, auth0User, permissions]);

  const value = useMemo(() => {
    return {
      user: userWithAuth as UserInfo,
      setUser,
      companyVendors,
      setCompanyVendors,
      analyticsProfile,
      setAnalyticsProfile,
      fiscalYearStartMonth: companyConfigurations.fiscalYearStartMonth,
      companyConfigurations,
      setCompanyConfigurations,
      loading,
      error,
      hasFieldVisibilityRestrictions,
      hasPopulationVisibilityRestrictions,
      hasAtsRestrictions,
      isFirstLogin,
      initialized,
    };
  }, [
    userWithAuth,
    companyVendors,
    setCompanyVendors,
    analyticsProfile,
    error,
    hasFieldVisibilityRestrictions,
    hasPopulationVisibilityRestrictions,
    initialized,
    loading,
    hasAtsRestrictions,
    isFirstLogin,
    companyConfigurations,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
