import { faker } from '@faker-js/faker';

import { UserAnalyticsProfile } from 'api/userApi';

import { User, UserInvitation } from '../index';

export const mockVendors = {
  ats: ['greenhouse'],
  hris: ['bamboo'],
  lms: ['udemy'],
};

export const mockWithoutATSVendor = {
  hris: ['bamboo'],
};

export const mockCompanyDomain = 'mockcompany';
export const mockCompany = {
  id: 1,
  name: 'omed',
  domain: mockCompanyDomain,
  auth0Id: 'org_company_1`',
  status: 'active',
  fiscalYearStartMonth: 1,
  rootNodes: ['ceo', 'chief executive', 'co-founder'],
  colorScheme: [],
  createdAt: '2010-10-01',
  updatedAt: '2010-10-01',
  discoveredCustomFields: [],
};

export const mockCompanyConfigurations = {
  fiscalYearStartMonth: 1,
  colorScheme: [],
  repeatColorScheme: false,
  organizationLeadershipTitles: [],
  organizationLeadershipReports: [],
  hasEngagementData: false,
};

export const userFactory = (overwrites?: Partial<User>): User => {
  return {
    accountType: 'normal',
    email: faker.internet.email(),
    auth0_user_id: faker.random.alphaNumeric(),
    field_visibility: 'full',
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
    headshot_url: faker.image.imageUrl(),
    hris_employee_id: faker.random.numeric(),
    id: faker.random.numeric(),
    is_staff: false,
    population_nodes_visible: [],
    population_visibility: 'full',
    blocked: false,
    ...overwrites,
  };
};

// User entity for a Guest User
export const mockGuestUser: User = {
  accountType: 'guest',
  first_name: 'tester',
  last_name: 'guest',
  headshot_url: '',
  email: 'tester+guest@knoetic.com',
  id: 'auth0|test_guest',
  auth0_user_id: 'auth0|test_guest',
  hris_employee_id: null,
  is_staff: false,
  field_visibility: 'full',
  population_nodes_visible: [],
  population_visibility: 'full',
  blocked: false,
};

export const mockAdminUser: User = {
  accountType: 'admin',
  first_name: 'tester',
  last_name: 'admin',
  headshot_url: '',
  email: 'test@knoetic.com',
  id: 'auth0|test',
  auth0_user_id: 'auth0|test',
  hris_employee_id: null,
  is_staff: true,
  field_visibility: 'full',
  blocked: false,
  population_nodes_visible: [],
  population_visibility: 'full',
};

export const mockRestrictedUser: User = {
  accountType: 'normal',
  first_name: 'tester',
  last_name: 'normal',
  headshot_url: '',
  email: 'tester+normal@knoetic.com',
  id: 'auth0|test_normal',
  auth0_user_id: 'auth0|test_normal',
  hris_employee_id: null,
  is_staff: false,
  field_visibility: 'full',
  blocked: false,
  population_nodes_visible: ['GCLMa7g76otzLPVnhlAhM'],
  population_visibility: 'restricted',
};

export const mockUsers: User[] = [mockAdminUser, mockRestrictedUser, mockGuestUser];

export const pendingUserInvitation: UserInvitation = {
  invitation_id: '3',
  invitee_first_name: 'Mary',
  invitee_last_name: 'Low',
  account_type: 'normal',
  invitee_email: 'mary+test@knoetic.com',
  field_visibility: 'full',
  population_visibility: 'full',
  is_expired: false,
};

export const mockUserInvitations: UserInvitation[] = [
  // Revoked
  {
    invitation_id: '1',
    invitee_first_name: 'Bob',
    invitee_last_name: 'Low',
    account_type: 'normal',
    invitee_email: 'bob+test@knoetic.com',
    is_expired: true,
    field_visibility: 'full',
    population_visibility: 'full',
  },
  // Accepted
  {
    invitation_id: '2',
    invitee_first_name: 'Sally',
    invitee_last_name: 'Low',
    account_type: 'normal',
    invitee_email: 'sally+test@knoetic.com',
    is_expired: false,
    field_visibility: 'full',
    population_visibility: 'full',
  },
  // Pending
  pendingUserInvitation,
];

export const mockAnalyticsProfile: UserAnalyticsProfile = {
  accountType: 'admin',
  auth0_user_id: 'auth0|test',
  optica_id: 'optica_1',
  org: {
    id: 'benchling',
    name: 'benchling',
    display_name: 'benchling',
  },
  email: 'test@knoetic.com',
  family_name: 'Doe',
  given_name: 'John',
  hasCompletedProfile: true,
  picture: '/media/user_profile_images/e2eefb7aed2b4521b67c995bbbfae62a.png',
  app_metadata: {
    admin: {
      staff: true,
      superadmin: true,
    },
    benchling: {
      population_visibility: 'full',
      field_visibility: 'full',
      nodes_visible: [],
      hris_employee_id: null,
    },
  },
  is_first_login: true,
};

export const mockAnalyticsProfileWithFieldRestrictions: UserAnalyticsProfile = {
  ...mockAnalyticsProfile,
  accountType: 'normal',
  app_metadata: {
    admin: {
      staff: true,
      superadmin: true,
    },
    benchling: {
      population_visibility: 'restricted',
      field_visibility: 'restricted',
      nodes_visible: [],
      hris_employee_id: null,
    },
  },
};
