import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery, useMutation, FetchResult } from '@apollo/client';

import { GET_ME, UPDATE_MEMBER } from 'queries/memberQueries';

import { Me } from 'types';

export interface UpdateMemberVariables {
  id: number;
  email?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  location?: string;
  industry?: string;
  companyLogo?: string;
  companyName?: string;
  companySize?: string;
  companyStage?: string;
  linkedinUrl?: string;
  headshotFrame?: string;
  headshotUrl?: string;
  bio?: string;
  interests?: [string];
  lastDataRefreshAt?: string; // Date string
}

export interface UpdateMemberResponse {
  getMe: Me;
}

interface GetMeResponse {
  getMe: Me;
}

export interface MemberContextInterface {
  loading: boolean;
  initialized: boolean;
  error?: Error | unknown | null;
  updating: boolean;
  member: Me;
  setMember: (newData: Me) => void;
  updateMember: (values: Omit<UpdateMemberVariables, 'id'>) => Promise<FetchResult<UpdateMemberResponse>>;
  toggleForumAdmin: () => void;
  isTrueForumAdmin: boolean;
  isForumAdmin: boolean;
}

export const MemberContext = createContext<MemberContextInterface>({
  loading: false,
  initialized: false,
  error: undefined,
  updating: false,
  member: ({} as unknown) as Me,
  setMember: () => undefined,
  updateMember: () => Promise.resolve({} as FetchResult<UpdateMemberResponse>),
  toggleForumAdmin: () => undefined,
  isTrueForumAdmin: false,
  isForumAdmin: false,
});

const MemberProvider = ({ children }: { children: React.ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const [isToggleFirstLoad, setIsToggleFirstLoad] = useState(true);
  const [member, setMemberState] = useState<Me>({} as Me);
  const [isTrueForumAdmin, setIsTrueForumAdmin] = useState(false);
  const [isToggleAdmin, setIsToggleAdmin] = useState(false);

  const toggleForumAdmin = useCallback(() => {
    if (!member.isForumAdmin) {
      return;
    }

    setIsToggleAdmin(!isToggleAdmin);
  }, [member.isForumAdmin, isToggleAdmin]);

  const { data, loading, error, client } = useQuery<GetMeResponse>(GET_ME, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    onError: () => setInitialized(true),
    onCompleted: () => setInitialized(true),
  });

  const [mutate, { loading: updating }] = useMutation<UpdateMemberResponse, UpdateMemberVariables>(UPDATE_MEMBER);

  useEffect(() => {
    if (!data?.getMe) {
      return;
    }

    setMemberState(data.getMe);
    if (isToggleFirstLoad) {
      setIsToggleAdmin(data.getMe.isForumAdmin);
      setIsTrueForumAdmin(data.getMe.isForumAdmin);
      setIsToggleFirstLoad(false);
    }
  }, [data, isToggleFirstLoad, member.isForumAdmin]);

  const updateMember = useCallback(
    (values: Omit<UpdateMemberVariables, 'id'>) =>
      mutate({
        variables: {
          ...values,
          id: data?.getMe.id || 0,
        },
      }),
    [data, mutate],
  );

  const setMember = useCallback(
    (newData: Me) => {
      client.cache.writeQuery({
        query: GET_ME,
        data: {
          getMe: {
            ...data?.getMe,
            ...newData,
          },
        },
      });
    },
    [data, client],
  );

  const value = useMemo(() => {
    return {
      loading,
      initialized,
      error,
      updating,
      member,
      setMember,
      updateMember,
      toggleForumAdmin,
      isTrueForumAdmin,
      isForumAdmin: isToggleAdmin,
    };
  }, [
    error,
    initialized,
    isToggleAdmin,
    isTrueForumAdmin,
    loading,
    member,
    setMember,
    toggleForumAdmin,
    updateMember,
    updating,
  ]);

  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>;
};

export const useMember = () => {
  const context = useContext(MemberContext);
  if (!context) {
    throw new Error('useMember must be used within an MemberProvider:');
  }
  return context;
};

export default MemberProvider;
