import { gql } from '@apollo/client';

export const MEMBER_FIELDS = gql`
  fragment MemberFields on Member {
    id
    firstName
    lastName
    title
    location
    headshotUrl
    headshotFrame
    companyStage
    companyName
    companyLogo
    companySize
    bio
    industry
    isForumAdmin
    linkedinUrl
    interests
    isBetaUser
    lastDataRefreshAt
    _count {
      receivedVotes
    }
    speakerLikesReceived
    memberType
  }
`;

export const GET_ME = gql`
  ${MEMBER_FIELDS}
  query GetMe {
    getMe {
      ...MemberFields
      email
      createdAt
      pollAnswersCount
      repliesCount
      eventsCount
      referrals {
        email
      }
      hasAgreedGuidelines
      hasAgreedToTermsAndConditions
    }
  }
`;

export const GET_MEMBER_METADATA = gql`
  query getMemberMetadata {
    getMemberMetadata {
      locationCount
      locations {
        value
      }
      members
    }
  }
`;

export const GET_MEMBER_BY_ID = gql`
  ${MEMBER_FIELDS}
  query GetMemberById($id: Int, $auth0UserId: String) {
    getMemberById(id: $id, auth0UserId: $auth0UserId) {
      ...MemberFields
      email
      postsCount
      repliesCount
      votedPostsCount
      followersCount
      followedMembersCount
      isFollowingMember
      isFollowedByMember
    }
  }
`;

export const GET_MEMBERS = gql`
  ${MEMBER_FIELDS}
  query GetMembers(
    $cursorId: Int
    $take: Int!
    $searchString: String
    $stageFilters: [String]
    $sizeFilters: [String]
    $locationFilters: [String]
    $includeKnoeticMembers: Boolean
  ) {
    getMembers(
      cursorId: $cursorId
      take: $take
      searchString: $searchString
      stageFilters: $stageFilters
      sizeFilters: $sizeFilters
      locationFilters: $locationFilters
      includeKnoeticMembers: $includeKnoeticMembers
    ) {
      members {
        ...MemberFields
        createdAt
        featuredOn
        isFollowingMember
        isFollowedByMember
      }
      count
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember(
    $id: Int!
    $email: String
    $title: String
    $firstName: String
    $lastName: String
    $headshotFrame: String
    $location: String
    $companyLogo: String
    $companyName: String
    $companySize: String
    $companyStage: String
    $bio: String
    $linkedinUrl: String
    $headshotUrl: String
    $interests: [String]
    $lastDataRefreshAt: String
  ) {
    updateMember(
      id: $id
      email: $email
      title: $title
      firstName: $firstName
      lastName: $lastName
      headshotFrame: $headshotFrame
      location: $location
      companyLogo: $companyLogo
      companyName: $companyName
      companySize: $companySize
      companyStage: $companyStage
      bio: $bio
      linkedinUrl: $linkedinUrl
      headshotUrl: $headshotUrl
      interests: $interests
      lastDataRefreshAt: $lastDataRefreshAt
    ) {
      id
      email
      title
      firstName
      lastName
      headshotFrame
      location
      companyLogo
      companyName
      companySize
      companyStage
      bio
      linkedinUrl
      headshotUrl
      interests
      lastDataRefreshAt
    }
  }
`;

export const GET_SUGGESTED_MEMBERS = gql`
  ${MEMBER_FIELDS}
  query GetSuggestedMembers($firstName: String!, $lastName: String!, $email: String!, $linkedinUrl: String) {
    getSuggestedMembers(firstName: $firstName, lastName: $lastName, email: $email, linkedinUrl: $linkedinUrl) {
      matches {
        ...MemberFields
      }
      isExact
    }
  }
`;

export const SEARCH_MEMBERS = gql`
  query SearchForMembers($searchString: String!) {
    searchForMembers(searchString: $searchString) {
      id
      firstName
      lastName
      title
      companyName
    }
  }
`;

export const SIGN_GUIDELINES = gql`
  mutation SignGuidelines {
    signGuidelines {
      id
      hasAgreedGuidelines
    }
  }
`;

export const AGREE_TO_TERMS_AND_CONDITIONS = gql`
  mutation AgreeToTermsAndConditions {
    agreeToTermsAndConditions {
      id
      hasAgreedToTermsAndConditions
    }
  }
`;

export const GET_MEMBER_FOLLOWERS = gql`
  query GetMemberFollowers($id: Int!, $cursorId: Int, $limit: Int) {
    getMemberById(id: $id) {
      id
      followers(limit: $limit, cursorId: $cursorId) {
        count
        cursor
        items {
          id
          member {
            id
            firstName
            lastName
            title
            companyName
            postsCount
            repliesCount
            headshotUrl
            isFollowingMember
            isFollowedByMember
            isBlockedByMember
          }
        }
      }
    }
  }
`;

export const GET_FOLLOWED_MEMBERS = gql`
  query GetFollowedMembers($id: Int!, $cursorId: Int, $limit: Int) {
    getMemberById(id: $id) {
      id
      followedMembers(limit: $limit, cursorId: $cursorId) {
        count
        cursor
        items {
          id
          followedMember {
            id
            firstName
            lastName
            title
            companyName
            postsCount
            repliesCount
            headshotUrl
            isFollowingMember
            isFollowedByMember
          }
        }
      }
    }
  }
`;

export const GET_EMBEDDED_FOLLOW_MEMBERS = gql`
  query GetEmbeddedFollowMembers {
    getEmbeddedFollowMembers {
      id
      fullName
      title
      companyName
      companySize
      postsCount
      repliesCount
      headshotUrl
      isFollowingMember
      isFollowedByMember
      location
    }
  }
`;
