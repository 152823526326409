/* eslint-disable camelcase */
import { API_ROOT } from 'config';

import base from './base';

interface Organization {
  branding?: {
    logo_url: string;
  };
  id: string;
  display_name: string;
  name: string;
}

interface OrganizationMetadata {
  population_visibility: 'full' | 'restricted' | 'restricted_self' | null;
  nodes_visible: string[];
  field_visibility: 'full' | 'restricted' | null;
  hris_employee_id: string | null;
}

// Definition for the "admin" metadata
interface AdminMetadata {
  staff?: boolean;
  superadmin?: boolean;
}

interface AppMetadata {
  admin?: AdminMetadata | undefined;
  [organizationName: string]: OrganizationMetadata | AdminMetadata | undefined;
}

type AccountType = 'normal' | 'guest' | 'admin';

export interface UserAnalyticsProfile {
  accountType: AccountType;
  org: Organization;
  auth0_user_id: string;
  optica_id: string | null;
  email: string;
  family_name: string;
  given_name: string;
  picture: string;
  app_metadata: AppMetadata;
  hasCompletedProfile: boolean | null;
  is_first_login: boolean | null;
}

export interface UpdateUserPayload {
  first_name?: string;
  last_name?: string;
  profile_img?: string;
}

const getUser = async () => {
  const response = await base.api.get<null, UserAnalyticsProfile>(`${API_ROOT}/user/me`);
  return response;
};

const updateUser = async (payload: UpdateUserPayload) => {
  const response = await base.api.patch(`${API_ROOT}/user/me`, payload);
  return response;
};

export const isOrganizationMetadata = (
  metadata: OrganizationMetadata | AdminMetadata | undefined,
): metadata is OrganizationMetadata => {
  return (
    metadata !== undefined &&
    'population_visibility' in metadata &&
    'nodes_visible' in metadata &&
    'field_visibility' in metadata &&
    'hris_employee_id' in metadata
  );
};

export default {
  getUser,
  updateUser,
};
